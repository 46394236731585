import React from "react";
import { useState } from "react";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import logoGris from "../Imagenes/Btn-Black.svg";

import "./FormularioDomicilio.css";

const FormularioDomicilio = () => {
  const [direccion, setDireccion] = useState();
  const [complemento, setComplemento] = useState();

  const [idCiudadSucursal, setIdCiudadSucursal] = useState();
  const [nameCiudadSucursal, setNameCiudadSucursal] = useState(
    "Selecciona una ciudad"
  );

  const [propiedadesCompanys, setPropiedadesCompany] = useState([]);

  const [listaCiudades, setListaCiudades] = useState([]);
  const [buscarDirreccion, setBuscarDirreccion] = useState();

  const urlHomeApp = `https://dev-api.menu.iguarayalabs.com/home/`;

  // HEADERS parametros de seguridad o configuraciones que tiene la pagina y son independientes de la navegacion...  // Header en la pagina. manda informacion especifica a
  useEffect(() => {
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    localStorage.setItem("typeSend", 0);
    obtenerCiudades();
  }, [urlHomeApp]);

  const obtenerCiudades = async () => {
    const urlCiudades = `https://dev-api.menu.iguarayalabs.com/cities`;
    const respuesta = await fetch(urlCiudades, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": window.location.hostname.split(".")[0],
      }),
    }).then((respuesta) => respuesta.json());
    setPropiedadesCompany(respuesta.properties);
    let infoCiudades = respuesta.data;
    setListaCiudades(infoCiudades);
  };

  /////////
  const enviarInfoBuscarDireccion = async () => {
    let idCiudad = localStorage.getItem("idCiudadSucursal");
    let direccion = localStorage.getItem("Direccion");

    try {
      let apiBuscarDireccion = `https://dev-api.menu.iguarayalabs.com/search/address`;
      const res = await fetch(apiBuscarDireccion, {
        method: "post",
        body: JSON.stringify({
          city_id: idCiudad,
          address: direccion,
        }),
        headers: new Headers({
          "pleizt-uuid": window.location.hostname.split(".")[0],
        }),
      });

      let respuestaJSON = await res.json();
      if (respuestaJSON.message !== "success") {
        alert(respuestaJSON.message);
      } else {
        let resBuscarDireccion = respuestaJSON.data;
        localStorage.setItem("idBranch", resBuscarDireccion.id_branchs);
        setBuscarDirreccion(resBuscarDireccion);
        navigate("/menu");
      }
    } catch (err) {
      console.log(err);
    }
  };

  ///////

  const enviarIdCiudad = (idCiudadSucursal, nameCiudadSucursal) => {
    localStorage.setItem("idCiudadSucursal", idCiudadSucursal);
    // console.log(idCiudadSucursal);
    // obtenerTiendasXCiudad(idCiudadSucursal);
    setIdCiudadSucursal(idCiudadSucursal);
    // setCiudad()
    setNameCiudadSucursal(nameCiudadSucursal);
  };

  const guardarTipoEnvio = (id) => {
    // 1. guardar la informacion en localStora
    localStorage.setItem("idTipoEnvio", id);
    // 2. se hace la navegacion >>>
    // 3. si es el tipo
  };

  let navigate = useNavigate();
  const guardarInfo = () => {
    if (nameCiudadSucursal === undefined || direccion === undefined) {
      alert("Por favor ingresar todos los datos");
    } else {
      console.log(buscarDirreccion);
      localStorage.setItem("nameCiudadSucursal", nameCiudadSucursal);
      localStorage.setItem("Direccion", direccion);
      localStorage.setItem("Complemento", complemento);
      localStorage.setItem("isAddress", true);
      enviarInfoBuscarDireccion();
      guardarTipoEnvio("1");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="containerPrincipal">
        <div className="head-formulario">
          <Link to={"/"}>
            <img
              src={logoGris}
              alt="logoGris"
              style={{
                width: "95px",
                height: "52px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            ></img>
          </Link>
        </div>

        <form className="form-domicilio" onSubmit={onSubmit}>
          <img
            src={propiedadesCompanys.company_logo}
            alt="logoCompany"
            style={{ zIndex: "1", width: "150px", top: "190px" }}
          />

          <h3>Ingresa tu dirección de entrega</h3>

          {(() => {
            if (listaCiudades !== undefined) {
              return (
                <Select
                  id="sucursales"
                  className="options"
                  defaultValue={{
                    label: nameCiudadSucursal,
                    value: idCiudadSucursal,
                  }}
                  // options={  optionsCiudades.map(city => ({ label: city.nameCity, value: city.id }))  }
                  options={listaCiudades.map((ciudad) => ({
                    label: `${ciudad.name}`,
                    value: `${ciudad.id}`,
                  }))}
                  onChange={(e) => {
                    enviarIdCiudad(e.value);
                    setNameCiudadSucursal(e.label);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 12,
                    width: 280,
                    /*colors: {
                      ...theme.colors,
                      primary25: colorHover,
                      primary: colorBoton,
                      primary50: colorBoton,
                    },*/
                  })}
                />
              );
            }
          })()}

          <input
            type="text"
            className="new-inputConfirmacion"
            placeholder="Dirección"
            onChange={(e) => setDireccion(e.target.value)}
          ></input>

          <input
            type="text"
            className="new-inputConfirmacion"
            placeholder="Punto de referencia ó casa, apto, torre,"
            onChange={(e) => setComplemento(e.target.value)}
          ></input>

          <button
            className="boton"
            onClick={guardarInfo}
            style={{
              margin: "40px",
              border: "none",
              padding: "9px",
              cursor: "pointer",
              width: "272px",
              borderRadius: "12px",
              background: "#28304A",
              height: "50px",
              fontSize: "14px",
              fontWeight: "700",
              color: "#ffffff",
            }}
          >
            Guardar
          </button>
        </form>
      </div>
    </>
  );
};

export default FormularioDomicilio;
