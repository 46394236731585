import React, { useEffect, useState } from "react";
import "./DetallePago.css";
import icoReembolso from "../Imagenes/Ico_reembolso.svg";
import flechaDerecha from "../Imagenes/Ico_next.svg";
import IcoLocacion from "../Imagenes/Ico_Locacion.svg";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import PopUpProgramarEntrega from "./PopUpProgramarEntrega";
import PopUpConCuantoPagas from "./PopUpConCuantoPagas";
import IcoCredito from "../Imagenes/Ico_credito.svg";
import { Header1 } from "../../componentes/header/Header1";
export const DetallePago = ({
  codigoCurrency,
  codigoPais,
  propiedadesCompany,
  color_botton,
}) => {
  let navigate = useNavigate();
  const [subTotal, setsubTotal] = useState(0);
  const [popUpEntrega, setPopUpEntrega] = useState(false);
  // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.
  const urlBase = `https://dev-api.menu.iguarayalabs.com`;
  //const urlBase = `http://api.menu-digital.test`;
  // setpopUpFechaEntrega
  const [popUpFechaEntrega, setpopUpFechaEntrega] = useState(false);
  const [metodoPago, setMetodoPago] = useState();
  const [isBotonActive, setIsBotonActive] = useState(false);

  const [preOrden, setPreOrden] = useState([]);

  // console.log(isBotonActive);
  const [indexPagoSeleccionada, setIndexPagoSeleccionada] = useState();

  const [isBotonActiveDomicilio, setIsBotonActiveDomicilio] = useState(true);
  // console.log(isBotonActiveDomicilio);

  const colorBoton = localStorage.getItem("colorBoton");
  const [listaMetodoDePago, setListaMetodoDePago] = useState([]);

  const [pagoEnLinea, setPagoEnLinea] = useState([]);

  useEffect(() => {
    obtenerMetodoDePago();
    //obtenerSubTotal();
    CalcularValor();
  }, []);

  const obtenerMetodoDePago = async () => {
    // const urlMetodoDePago = `${ process.env.REACT_APP_API_URL }type-pay`;
    const urlMetodoDePago = `https://dev-api.menu.iguarayalabs.com/type-pay`;

    var pleiztUuid = localStorage.getItem("pleiztUuid");
    const respuesta = await fetch(urlMetodoDePago, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid,
      }),
    }).then((respuesta) => respuesta.json());

    let infoMetodoDePago = respuesta.data;
    // console.log(infoMetodoDePago);
    setListaMetodoDePago(infoMetodoDePago);
  };

  const obtenerSubTotal = () => {
    var subTotal = parseInt(localStorage.getItem("precioTotal"));
    setsubTotal(subTotal);
  };

  const idUsuarioLogeado = localStorage.getItem("idUsuarioLogeado");

  const enviarInfoMetodoPago = (metodoPago) => {
    localStorage.setItem("Metodo de pago", metodoPago);
    setMetodoPago(metodoPago);
  };

  var idTipoEnvio = localStorage.getItem("idTipoEnvio");
  // const idTipoEnvio = 1;
  const nombreTienda = localStorage.getItem("nameTienda");
  const direccionUsuario = localStorage.getItem("Direccion");
  const tipoEntrega = localStorage.getItem("TipoEntrega");

  const BotonSeleccionado = styled.div`
    width: auto;
    height: 56px;
    border-radius: 12px;
    color: ${(props) => (props.$isBotonActive ? "#FFFFFF" : "#4D4D4D")};
    border: ${(props) => (props.$isBotonActive ? "0px" : "1px solid #4D4D4D")};
    gap: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0px 10px 0px 10px;
    background: ${(props) => (props.$isBotonActive ? colorBoton : "#FFFFFF")};
  `;

  const [valorInput, setValorInput] = useState(0);

  var descuento = 0;
  var valorEnPesos = parseInt(valorInput);
  const totalAPagar = subTotal - descuento;
  var totalAPagarEfectivo = 0;

  const enviarTotalAPagar = () => {
    if (metodoPago === 1) {
      console.log("total a Pagar con Efectivo");
      // console.log(totalAPagarEfectivo);
      //totalAPagarEfectivo();
    } else {
      console.log("totalAPagar con otro medio de pago:");
      ///console.log(totalAPagar);
      //  totalAPagar();
    }
    enviarInfoOrdenMesas();
    // enviarInfoOrdenMesa();
  };

  const informacionProdSelecs = () => {
    let arrayToppings = [];
    let infoProductoSeleccionado = JSON.parse(
      localStorage.getItem("infoProductoSeleccionado")
    );
    // console.log("infoProductoSeleccionado: " + infoProductoSeleccionado);

    infoProductoSeleccionado.map((item) => {
      var listaToppings = [];
      item.toppings.map((item) => {
        item.topingsSeleccionados.map((item) => {
          let uuidToppings = item.uuid;
          let cantidad = "1";

          var jsonTopping = {
            uuid_toppings: uuidToppings,
            quantity: cantidad,
          };
          listaToppings.push(jsonTopping);
        });
      });
      let jsonProducto = {
        uuid_product: item.idPlatoSelec,
        quantity: item.cantidadProducto,
        observations: "-----",
        toppings: listaToppings,
      };
      arrayToppings.push(jsonProducto);
    });
    return arrayToppings;
  };

  const [numeroPedidoFinal, setNumeroPedidoFinal] = useState();

  const enviarInfoOrdenMesas = async () => {
    let metodoPago = localStorage.getItem("Metodo de pago");
    let tipoEntrega = localStorage.getItem("idTipoEnvio");
    let arrayListaProductos = informacionProdSelecs();
    let uuid_address = localStorage.getItem("uuid_address");
    let name = localStorage.getItem("Nombres");
    let lastname = localStorage.getItem("lastname");
    let phone = localStorage.getItem("Celular");
    let address = localStorage.getItem("Direccion");
    let city = localStorage.getItem("idCiudadSucursal");
   
    if(metodoPago === null ){
      alert('Seleccione metodo de pago');
    }else{
      let typedocument = localStorage.getItem("typedocument");
      let document = localStorage.getItem("document");

      let guests = {
        name: name,
        email: lastname,
        phone: phone,
        address: address,
        city: city,
        document: document,
        type_document: typedocument,
      };

      try {
        const apiOrdenes = urlBase + `/orders-app`;

        var pleiztUuid = localStorage.getItem("pleiztUuid");
        const res = await fetch(apiOrdenes, {
          method: "post",
          body: JSON.stringify({
            // aqui se arma el json para enviarlo al api, de acuerdo a como esta en postman (POST)
            guests: guests,
            uuid_address: uuid_address,
            delivery_type: tipoEntrega,
            payment_method_id: metodoPago,
            product: arrayListaProductos,
          }),
          headers: new Headers({
            "pleizt-uuid": pleiztUuid,
          }),
        });
        let respuestaJSON = await res.json();
        console.log("informacion: " + respuestaJSON);
        console.log(respuestaJSON);
        if (respuestaJSON.success == "success") {
          let respuestaOrden = respuestaJSON.data.service_id;
          /////
          let respuestaPago = respuestaJSON.data.pay;
          setPagoEnLinea(respuestaPago);
          if (respuestaPago.status === true) {
            window.location.replace(respuestaPago.url);
          } else {
            setNumeroPedidoFinal(respuestaOrden);
          }
          localStorage.setItem("pleiztService", respuestaJSON.data.service_id);
          localStorage.removeItem("infoProductoSeleccionado");
          setNumeroPedidoFinal(respuestaOrden);
          localStorage.removeItem("infoProductoSeleccionado");
          localStorage.removeItem("Metodo de pago");
          navigate("/resumenPedido/"+respuestaJSON.data.service_id);
        } else {
          alert(respuestaJSON.message);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const CalcularValor = async () => {
    let metodoPago = localStorage.getItem("Metodo de pago");
    let tipoEntrega = localStorage.getItem("idTipoEnvio");
    let arrayListaProductos = informacionProdSelecs();
    let uuid_address = localStorage.getItem("uuid_address");

    ////
    let name = localStorage.getItem("name");
    let email = localStorage.getItem("email");
    let lastname = localStorage.getItem("lastname");
    let phone = localStorage.getItem("phone");
    let address = localStorage.getItem("Direccion");
    let city = localStorage.getItem("idCiudadSucursal");
    let uuidShop = localStorage.getItem("uuidShop");
    let typeSend = localStorage.getItem("typeSend");
    
    let guests = {
      name: name,
      lastname: lastname,
      email: email,
      phone: phone,
      address: address,
      city: city,
      uuidShop: uuidShop,
    };
    try {
      const apiOrdenes = `https://dev-api.menu.iguarayalabs.com/pre-orders-app`;

      var pleiztUuid = localStorage.getItem("pleiztUuid");
      const res = await fetch(apiOrdenes, {
        method: "post",
        body: JSON.stringify({
          // aqui se arma el json para enviarlo al api, de acuerdo a como esta en postman (POST)
          guests: guests,
          uuid_address: uuid_address,
          delivery_type: typeSend,
          payment_method_id: metodoPago,
          product: arrayListaProductos,
        }),
        headers: new Headers({
          "pleizt-uuid": pleiztUuid,
        }),
      });
      let respuestaJSON = await res.json();
      console.log("informacion: " + respuestaJSON);
      console.log(respuestaJSON);
      if (respuestaJSON.success == "success") {
        setPreOrden(respuestaJSON.data);
        console.log(preOrden.base_value);
      } else {
        alert(respuestaJSON.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="App">
      <div className="container-principal-carrito">
        <header className="App-header">
          <Header1 propiedadesCompany={propiedadesCompany} />
        </header>
        <div className="container-list-pedido ">
          <div className="container-body">
            <div className="container--tipo--entrega">
              <p className="title-tipo--entrega">Tipo entrega: </p>

              <div className="caja-end">
                {/* NO BORRAR OJO!! */}
                <p
                  id="1"
                  className={`tipo--entrega ${
                    isBotonActiveDomicilio ? "" : "active"
                  }`}
                  onClick={() => {
                    setIsBotonActiveDomicilio(true);
                  }}
                >
                  Domicilio{" "}
                </p>
              </div>
            </div>

            <section className="container-sub-titulos">
              <div className="container-agregar-tarjeta">
                <img src={IcoLocacion} alt="IcoLocacion"></img>
                {(() => {
                  if (isBotonActiveDomicilio === true) {
                    return (
                      <Link
                        to={"/misdirecciones/"}
                        style={{ textDecoration: "none" }}
                      >
                        <p className="subtitulos"> {direccionUsuario} </p>
                      </Link>
                    );
                  } else {
                    return (
                      <>
                        <p className="subtitulos"> {nombreTienda} </p>
                        {/* <p className='subtitulos'>Pizza Planet Centro</p> */}
                      </>
                    );
                  }
                })()}
              </div>
            </section>
            <div className="new-type-pay">Metodo de pago</div>

            <div className="container-info-detalle-pago">
              {(() => {
                if (listaMetodoDePago !== undefined) {
                  return listaMetodoDePago.map((item, i) => {
                    var idMetodoPago = item.id;

                    return (
                      <>
                        <BotonSeleccionado
                          key={item.id}
                          id={item.id}
                          className="boton-metodo-pago"
                          value={item.id}
                          $isBotonActive={i === indexPagoSeleccionada}
                          onClick={() => {
                            enviarInfoMetodoPago(idMetodoPago);
                            setMetodoPago(idMetodoPago);
                            setIsBotonActive(!isBotonActive);
                            setIndexPagoSeleccionada(i);
                          }}
                        >
                          <p id={item.id} className="title-metodo-pago">
                            <img
                              src={IcoCredito}
                              className="img-metodo-pago"
                            ></img>
                            {item.name}
                          </p>
                        </BotonSeleccionado>
                      </>
                    );
                  });
                }
              })()}
            </div>

            {/* Aqui el set del metodoPago es number */}
            {(() => {
              if (metodoPago === 1) {
                return (
                  <>
                    <section
                      className="container-sub-titulos"
                      onClick={() => {
                        setPopUpEntrega(true);
                      }}
                    >
                      <div className="container-agregar-tarjeta">
                        <img src={icoReembolso} alt="icoReembolso"></img>
                        <p className="subtitulos">¿Con cuanto pagas?</p>
                      </div>

                      <div className="container-agregar-tarjeta">
                        <p className="subtitulos">
                          {" "}
                          {valorEnPesos.toLocaleString(codigoPais, {
                            style: "currency",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            currency: codigoCurrency,
                          })}{" "}
                        </p>
                        <img src={flechaDerecha} alt="icoReembolso"></img>
                      </div>
                    </section>
                  </>
                );
              }
            })()}
          </div>

          <div className="fondo-boton-detail" st>
            <section className="container--detalle-pago">
              <div className="element-info">
                <div className="container-info-orden">
                  <p className="fecha-hora">Subtotal</p>
                  <p className="subtotal">{preOrden.base_value}</p>
                </div>
                <div className="container-info-orden">
                  <p className="fecha-hora">Valor domicilio</p>
                  <p className="fecha-hora">{preOrden.delivery_value} </p>
                </div>

                <hr className="linea-punteada" />
                <div className="container-info-orden">
                  <h4> Total </h4>
                  <h4>{preOrden.total_value} </h4>
                </div>
                <hr className="linea-punteada" />
              </div>

              <div className="container-info-orden">
                <Link to={"/shoppingCart/"} style={{ textDecoration: "none" }}>
                  <button className="new-cancel-pay">Cancelar</button>
                </Link>
                <button
                  onClick={() => enviarTotalAPagar()}
                  className="new-button-pay"
                >
                  Pagar
                </button>
              </div>
            </section>
          </div>

          <PopUpConCuantoPagas
            popUpEntrega={popUpEntrega}
            setPopUpEntrega={setPopUpEntrega}
            setValorInput={setValorInput}
            valorInput={valorInput}
            codigoPais={codigoPais}
            codigoCurrency={codigoCurrency}
            propiedadesCompany={propiedadesCompany}
            color_botton={color_botton}
          />

          <PopUpProgramarEntrega
            popUpFechaEntrega={popUpFechaEntrega}
            setpopUpFechaEntrega={setpopUpFechaEntrega}
            propiedadesCompany={propiedadesCompany}
            color_botton={color_botton}
          />
        </div>
      </div>
    </div>
  );
};
