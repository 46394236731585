import { useEffect, useState } from "react";
import "./App.css";
import { ListaPedido } from "./componentes/ListaPedido/ListaPedido";
import {
  Route,
  Routes,
} from "react-router-dom";

import { DetalleAlternativo } from "./componentes/DiseñoAlternativoDetalle/DetalleAlternativo";
import RegistroGuests from "./componentes/RegistroUsuario/RegistroGuests";
import FormularioMesa from "./componentes/FormularioMesa/FormularioMesa";
import FormularioDomicilio from "./componentes/FormularioDomicilio/FormularioDomicilio";
import AgregarTarjeta from "./componentes/AgregarTarjeta/AgregarTarjeta";
import ListaTiendas from "./componentes/ListaTiendas/ListaTiendas";
import { DetallePago } from "./componentes/DetallePago/DetallePago";
import { ResumenPedido } from "./componentes/ListaOrdenes/ResumenPedido";
import { SeguimientoPedido } from "./componentes/SeguimientoPedido/SeguimientoPedido";
///////
import { NewHome } from "./componentes/Home/NewHome";

import { Menu } from "./componentes/menu/Menu";

import { MenuCategory } from "./componentes/menu/MenuCategory";

import { OrderSummary } from "./componentes/ListaOrdenes/OrderSummary";

import { Seguimiento } from "./componentes/ListaOrdenes/Seguimiento";


function AppMenuDigital() {
  // para formatear el precio. para darle formato a los precios.
  const codigoPais = "es-CO";
  const codigoCurrency = "COP";

  const [propiedadesCompany, setPropiedadesCompany] = useState([]);

  const {
   
    color_hover,
    color_botton,
   
  } = propiedadesCompany;

  const urlHomeApp = `https://dev-api.menu.iguarayalabs.com/home/`;

  // HEADERS parametros de seguridad o configuraciones que tiene la pagina y son independientes de la navegacion...  // Header en la pagina. manda informacion especifica a
  useEffect(() => {
    var pleiztUuid = localStorage.getItem("pleiztUuid");
    if (pleiztUuid !== null) {
      (async function () {
        const res = await fetch(urlHomeApp, {
          method: "get",
          headers: new Headers({
            "pleizt-uuid": pleiztUuid, // y se reemplaza la informacion
            "pleizt-uuids": pleiztUuid, // y se reemplaza la informacion
          }),
        }).then((res) => res.json());
        var mensajeExito = res.success;
        if (mensajeExito === "success") {
          setPropiedadesCompany(res.properties);
          setListaTiendas();          
        }
      })();
    }
  }, [urlHomeApp]);


  const [listaTiendas, setListaTiendas] = useState([]);

  return (
    <Routes>
      <Route path="/" element={<NewHome />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/menu/category" element={<MenuCategory />} />
      <Route
        path="/detail/:idDetalleAlternativo"
        element={
          <DetalleAlternativo
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
          />
        }
      />
      <Route
        path="/shoppingCart/"
        element={
          <ListaPedido
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            color_botton={color_botton}
            color_hover={color_hover}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/detallePago/"
        element={
          <DetallePago
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/RegistroGuests/"
        element={<RegistroGuests propiedadesCompany={propiedadesCompany} />}
      />
      
      <Route
        path="/FormularioMesa/"
        element={
          <FormularioMesa
            propiedadesCompany={propiedadesCompany}
            listaTiendas={listaTiendas}
          />
        }
      />
      <Route
        path="/FormularioDomicilio/"
        element={
          <FormularioDomicilio
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/detailAlternativo/:idDetalleAlternativo"
        element={
          <DetalleAlternativo
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
          />
        }
      />
      <Route
        path="/home"
        element={
          <NewHome codigoCurrency={codigoCurrency} codigoPais={codigoPais} />
        }
      />
      <Route
        path="/detail-order/:idDetalle"
        element={
          <ResumenPedido
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/seguimiento/:idDetalle"
        element={
          <Seguimiento
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/resumenPedido/:idDetalle"
        element={
          <ResumenPedido
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/OrderSummary/:idDetalle"
        element={
          <OrderSummary
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/agregarTarjeta/"
        element={
          <AgregarTarjeta
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
            propiedadesCompany={propiedadesCompany}
          />
        }
      />
      <Route
        path="/ListaTiendas/"
        element={<ListaTiendas propiedadesCompany={propiedadesCompany} />}
      />
      <Route
        path="/seguimientoPedido/"
        element={
          <SeguimientoPedido
            propiedadesCompany={propiedadesCompany}
            codigoCurrency={codigoCurrency}
            codigoPais={codigoPais}
          />
        }
      />
    </Routes>
  );
}
export default AppMenuDigital;
